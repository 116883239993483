require("core-js/modules/es.array.slice");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.string.split");

(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "vant/es/icon/style", "vant/es/icon"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("vant/es/icon/style"), require("vant/es/icon"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.style, global.icon);
    global.checkListItem = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _style, _icon) {
  "use strict";

  var _interopRequireDefault = require("/root/deploy/iot-product-tool/node_modules/@babel/runtime/helpers/interopRequireDefault");

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _icon = _interopRequireDefault(_icon);
  var _default2 = {
    name: "CheckListItem",
    components: {
      Icon: _icon.default
    },
    props: {
      item: Object,
      default: function _default() {
        return {};
      }
    },
    data: function data() {
      var _this$item = this.item,
          probeId = _this$item.probeId,
          updateAt = _this$item.updateAt,
          passed = _this$item.passed;
      var _probeId = '';
      var time = '';

      if (probeId) {
        _probeId += probeId.slice(0, 4);
        _probeId += '  ';
        _probeId += probeId.slice(4, 8);
        _probeId += '  ';
        _probeId += probeId.slice(8, 12);
        _probeId += '  ';
        _probeId += probeId.slice(12, 16);
      }

      if (updateAt) {
        var t = updateAt.split('T').length > 1 ? new Date(updateAt) : new Date(updateAt * 1000);
        time = t.getFullYear();
        time += "/".concat(t.getUTCMonth() + 1 > 9 ? t.getUTCMonth() + 1 : '0' + (t.getUTCMonth() + 1));
        time += "/".concat(t.getDate() > 9 ? t.getDate() : '0' + t.getDate());
        time += "   ".concat(t.getHours() > 9 ? t.getHours() : '0' + t.getHours());
        time += ":".concat(t.getMinutes() > 9 ? t.getMinutes() : '0' + t.getMinutes());
        time += ":".concat(t.getSeconds() > 9 ? t.getSeconds() : '0' + t.getSeconds());
      }

      return {
        probeId: _probeId,
        time: time,
        passed: passed
      };
    },
    methods: {
      clickItem: function clickItem() {
        var vm = this;
        vm.$router.push({
          path: "/ryProbe/info",
          query: {
            uuid: vm.item.probeId
          }
        });
      }
    }
  };
  _exports.default = _default2;
});