var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: { "component-check-list-item": true, pass: _vm.passed },
      on: { click: _vm.clickItem }
    },
    [
      _c("div", { staticClass: "item-title" }, [_vm._v(_vm._s(_vm.probeId))]),
      _c("div", { staticClass: "item-time" }, [_vm._v(_vm._s(_vm.time))]),
      _c("Icon", { attrs: { name: "arrow" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }