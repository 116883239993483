require("core-js/modules/es.array.concat");

require("core-js/modules/es.array.includes");

require("core-js/modules/es.array.index-of");

require("core-js/modules/es.function.name");

require("core-js/modules/es.object.to-string");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.string.replace");

require("core-js/modules/es.string.split");

require("core-js/modules/es.string.trim");

require("core-js/modules/web.dom-collections.iterator");

(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "vant/es/toast/style", "vant/es/toast", "vant/es/dialog/style", "vant/es/dialog", "vant/es/button/style", "vant/es/button", "vant/es/tab/style", "vant/es/tab", "vant/es/tabs/style", "vant/es/tabs", "vant/es/search/style", "vant/es/search", "@/components/ryProbe/checkListItem"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("vant/es/toast/style"), require("vant/es/toast"), require("vant/es/dialog/style"), require("vant/es/dialog"), require("vant/es/button/style"), require("vant/es/button"), require("vant/es/tab/style"), require("vant/es/tab"), require("vant/es/tabs/style"), require("vant/es/tabs"), require("vant/es/search/style"), require("vant/es/search"), require("@/components/ryProbe/checkListItem"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.style, global.toast, global.style, global.dialog, global.style, global.button, global.style, global.tab, global.style, global.tabs, global.style, global.search, global.checkListItem);
    global.list = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _style, _toast, _style2, _dialog, _style3, _button, _style4, _tab, _style5, _tabs, _style6, _search, _checkListItem) {
  "use strict";

  var _interopRequireDefault = require("/root/deploy/iot-product-tool/node_modules/@babel/runtime/helpers/interopRequireDefault");

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _toast = _interopRequireDefault(_toast);
  _dialog = _interopRequireDefault(_dialog);
  _button = _interopRequireDefault(_button);
  _tab = _interopRequireDefault(_tab);
  _tabs = _interopRequireDefault(_tabs);
  _search = _interopRequireDefault(_search);
  _checkListItem = _interopRequireDefault(_checkListItem);
  var _default = {
    name: "RyProbeList",
    components: {
      Search: _search.default,
      Tabs: _tabs.default,
      Tab: _tab.default,
      CheckListItem: _checkListItem.default,
      Button: _button.default
    },
    data: function data() {
      var ossHost = this.$config.base.ossHost;
      return {
        ossHost: ossHost,
        name: "",
        value: "",
        title1: "全部",
        title2: "未通过",
        activeIndex: 0,
        list: [],
        isShowAll: false,
        loading: false
      };
    },
    created: function created() {
      var vm = this;
      var USER_NAME = vm.$config.keys.USER_NAME;
      var name = vm.$localStorage.getItem(USER_NAME);
      var uuid = vm.$localStorage.getItem('uuid');
      var fType = vm.$strTool.filterFactoryType(uuid);

      if (vm.$config.base.fList.indexOf(fType) > -1) {
        vm.name = name;

        if (!name) {
          vm.$router.replace({
            path: "/factory/login"
          });
          return;
        }

        !['yingwo', 'wangbo', 'lexing'].includes(fType) && vm.$router.replace({
          path: "/factory/index"
        });
      } else {
        vm.$router.replace({
          path: "404"
        });
      }

      vm.pageNo = 1;
      vm.pageSize = 10;
      vm.weixinInit();
      vm.loadCheckList();
    },
    methods: {
      switchAccount: function switchAccount() {
        var vm = this;

        _dialog.default.confirm({
          title: "提示",
          message: "是否确认退出当前账号？",
          confirmButtonColor: "#32BE32"
        }).then(function () {
          var USER_NAME = vm.$config.keys.USER_NAME;
          vm.$localStorage.removeItem(USER_NAME);
          vm.$router.replace({
            path: "/factory/login?type=1"
          });
        }).catch(function () {// on cancel
        });
      },
      weixinInit: function weixinInit() {
        var vm = this;
        vm.$http({
          type: "get",
          url: "https://m.yudada.com/jssdk/configuration?url=".concat(location.href)
        }).then(function (res) {
          var _res$data = res.data,
              appId = _res$data.appId,
              nonceStr = _res$data.nonceStr,
              signature = _res$data.signature,
              timestamp = _res$data.timestamp;
          window.wx.config({
            debug: false,
            appId: appId,
            timestamp: timestamp,
            nonceStr: nonceStr,
            signature: signature,
            jsApiList: ["scanQRCode"]
          });
        });
      },
      scan: function scan() {
        var vm = this;
        window.wx.scanQRCode({
          needResult: 1,
          scanType: ["barCode", "qrCode"],
          success: function success(res) {
            var result = res.resultStr;

            if (!result) {
              _dialog.default.confirm({
                title: "提示",
                message: "非探头条形码~",
                confirmButtonColor: "#32BE32",
                showCancelButton: false
              });

              return;
            }

            vm.onSearch(result);
          }
        });
      },
      onSearch: function onSearch(code) {
        var vm = this; // if (vm.value.trim().length != 16) {
        //     Toast.fail({
        //         message: '请输入16位ID编码查询',
        //         forbidClick: false,
        //         overlay: true,
        //         duration: 2000,
        //     });
        //     return
        // }

        vm.tt = _toast.default.loading({
          message: "加载中...",
          forbidClick: true,
          overlay: true,
          duration: 0
        });
        var probeId = code || vm.value.trim();
        "".concat(probeId).split(',')[1] && (probeId = probeId.split(',')[1]);
        var len = "".concat(probeId).length > 6 ? 9 - "".concat(probeId).length : 8 - "".concat(probeId).length;

        if (len > 0 && "".concat(probeId).length != 8) {
          len == 1 && (probeId = "0".concat(probeId));
          len == 2 && (probeId = "00".concat(probeId));
          len == 3 && (probeId = "000".concat(probeId));
          len == 4 && (probeId = "0000".concat(probeId));
          len == 5 && (probeId = "00000".concat(probeId));
          len == 6 && (probeId = "000000".concat(probeId));
          len == 7 && (probeId = "0000000".concat(probeId));
        }

        vm.$http({
          type: "get",
          url: "".concat(vm.$config.base.PROBE_BASE_URL, "probe"),
          params: {
            probeId: probeId
          }
        }).then(function (res) {
          vm.tt.clear();

          if (res.data) {
            vm.$router.push({
              path: "/ryProbe/info",
              query: {
                uuid: probeId
              }
            });
          } else {
            _toast.default.fail({
              message: '未找到对应探头的质检记录，请重新确认',
              forbidClick: true,
              overlay: true,
              duration: 2000
            });
          }
        });
      },
      onCancel: function onCancel() {},
      changeTab: function changeTab() {
        var vm = this;

        if (vm.activeIndex > -1) {
          vm.list = [];
          vm.pageNo = 1;
          vm.isShowAll = false;
          vm.loadMore();
        }
      },
      loadMore: function loadMore() {
        var vm = this;

        if (vm.loading) {
          return;
        }

        vm.loadCheckList();
      },
      loadCheckList: function loadCheckList() {
        var vm = this;
        vm.loading = true;
        vm.$http({
          type: "get",
          url: "".concat(vm.$config.base.PROBE_BASE_URL, "probeList"),
          params: {
            pageNo: vm.pageNo,
            pageSize: vm.pageSize,
            type: vm.activeIndex + 1
          },
          except: true
        }).then(function (res) {
          if (!res.data) {
            (0, _toast.default)("网络错误");
            return;
          }

          var _ref = res.data || {},
              probeList = _ref.probeList,
              totalCount = _ref.totalCount,
              failedCount = _ref.failedCount;

          vm.title1 = "\u5168\u90E8(".concat(totalCount, ")");
          vm.title2 = "\u672A\u901A\u8FC7(".concat(failedCount, ")");
          vm.list = vm.list.concat(probeList || []);
          vm.loading = false;

          if (!probeList || probeList.length < vm.pageSize) {
            vm.isShowAll = true;
          } else {
            vm.pageNo++;
          }
        });
      }
    }
  };
  _exports.default = _default;
});